.App {
    height: max-content;
}

#table {
    height: 650px;
    overflow-y: scroll;
}

thead {
    position: sticky;
    top: 0;
    background-color: rgb(0, 0, 0);
    color: white;
}